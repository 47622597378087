select:disabled {
    opacity: 1
}
.form-select {
    display: block;
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    color: #6c6c6e ;
    font-weight: 600;
    min-height: 32px !important;
    height: 32px !important;
    max-height: 32px !important;
    padding: .4rem 2.7rem .5rem .9rem;
    -moz-padding-start: calc(.9rem - 3px);

  
   
    background-color: hsl(0, 0%, 100%);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .9rem center;
    background-size: 16px 12px;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-select {
        -webkit-transition: none;
        transition: none
    }
}
@media (prefers-reduced-motion:reduce) {
    .form-select {
        -webkit-transition: none;
        transition: none
    }
}

.form-select:focus {
    border-color: hsl(0, 0%, 80%);
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(64, 81, 137, .25);
    box-shadow: 0 0 0 0 rgba(64, 81, 137, .25)
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
    padding-right: .9rem;
    background-image: none
}

.form-select:disabled {
    background-color: hsl(0, 0%, 80%)
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 hsl(0, 0%, 80%)
}

.form-select-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .7109375rem;
    border-radius: .2rem
}

.form-select-lg {
    padding-top: .7rem;
    padding-bottom: .7rem;
    padding-left: 1.2rem;
    font-size: 1.015625rem;
    border-radius: .3rem
}

// react select picker

.rs-picker-toggle.rs-btn-sm {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;
    padding-left: 15px !important;
  
}
.rs-picker-default .rs-picker-toggle {
   
    
    border: 1px solid hsl(0, 0%, 80%) !important;
    border-radius: .25rem !important;
    cursor: pointer;
    display: inline-block;
    padding-right: 32px;
    position: relative;
    z-index: 5;
    font-family: "Poppins", sans-serif;
    font-size: 11px !important;
    color: #2e2e30 !important;
    font-weight: 600;
    min-height: 32px !important;
    height: 32px !important;
    max-height: 32px !important;
 
}
.rs-picker-toggle .rs-picker-toggle-placeholder {
    color: #6c6c6e  !important;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret, .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
    right: 16px !important;
    top: 5px;
}
.rs-picker-toggle-caret {
    -ms-flex-align: center;
    align-items: center;
    color: #131314  !important;
   
    font-size: 12px;
    height: 20px;
    margin-left: 2px;
    right: 12px;
    top: 8px;
}
.input_hight{
    max-height: 32px !important;
    height: 32px !important;
    min-height: 32px !important;
    font-size: 12px !important;
    font-weight: 700;
    box-shadow: none !important;
    caret-color: transparent;
    
}
.input_hight:focus{
    border: 1px solid #ced4da;
    caret-color: transparent;
}

.input_hight_control{
    max-height: 32px !important;
    height: 32px !important;
    min-height: 32px !important;
    font-size: 12px !important;
    font-weight: 700;
    box-shadow: none !important;
    
}

.scriptradio{
    display: flex;
    gap: 20px;
    .form-check {
        position: relative;
        display: block;
        padding-left: 0;
    }
}

.chart-title{
    font-size: 30px;
}
.chart-title-num{
    color:rgba(245,130,31,255);
}

.errorMessage-file{
    color: red;
}
.surveyques-upload{
    .input_hight_control {
         max-height: 38px !important; 
         height: 38px !important; 
         min-height: 38px !important; 
        font-size: 12px !important;
        font-weight: 700;
        box-shadow: none !important;
    }
    
    .form-control {
        display: block;
        width: 100%;
        /* height: calc(1.5em + 0.75rem + 2px); */
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}

.NotFound{
    justify-content: center;
    padding-top: 100px;
    img{
        width: 140px;
    }
}

.NotFound_btn{
    color: #eeeef1;
    box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
    /* text-align: left; */
    
    font-size: 14px !important;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    border: white;
    border-radius: 5px;
    width: 20% !important ;
    background-color:#031b4e !important ;
    
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}



.rs-picker-menu.rs-picker-check-menu, .rs-picker-menu.rs-picker-select-menu {
    padding-top: 6px;
    max-width: 207px !important;
}
.rs-picker-menu .rs-picker-search-bar {
    padding: 6px 3px 12px !important;
    position: relative;
}
.upload_btn_survey{
    width: auto !important;
}