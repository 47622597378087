.basic-single .css-13cymwt-control{

  min-height: 32px !important;
  height: 32px !important;
  max-height: 32px !important;

}
.basic-single .css-1fdsijx-ValueContainer{
  font-size: 10px !important;
 
  color: #1e1e2d !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
}
.basic-single .css-1xc3v61-indicatorContainer{
  padding: 3px !important;
}

.basic-single .css-13cymwt-control .css-hlgwow {
  font-size: 10px !important;
  
  color: #1e1e2d !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
}
.work_service_img{
  margin-top: 35px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

}
.work_service_img img{
  width: 100px;
  transition: all .3s ease;
}
.work_service_img img:hover{
  transform: scale(1.08);
}

.counter:hover{
  color: #f3a033 ; 
   img{
    transform: scale(1.08);
}
}


// dashboard css
.service-right_imgcircle{
  max-width: 90px;
  max-height: 90px !important;
  position: relative;
  display:inline-block;
  top: -325px;
  left: 150px;
}
.dashboard_survey{
background-color: #ffffff;
}

.work-part-inner {
  padding: 0px 0px;
  span {
    list-style: none;
    padding: 0;
    margin: 0;
}
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}



.work-counter .counter-nummber {
    border: 5px solid #FFA800;
    border-radius: 100%;
    width: 200px;
    height: 190px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 35px;
    font-weight: 500;
    color: #094990;
    margin: 0 auto;
}
.work-counter .counter-name {
  background: #ffffff;
  margin-top: -30px;
  width: 100%;
  color: #EA3516;
  line-height: 22px;
  display: block;
  font-weight: 800 !important;
  font-size: 16px !important;
  height: 30px;
  padding-top: 6px;
}

.card_dash{

 background-color: #094990;
 border-radius: 10px;
 padding: 20px;
 .section-title{
  color: #FFFFFF;
 

 }
 p{
  color: #FFFFFF;margin-top: 20px;
 }
 .btn-oreng {
  padding: 10px 20px 10px 20px;
  margin-top: 20px;
  font-size: 19px;
  background: #FFA800;
    border: 1px solid #FFA800;
    color: #FFFFFF;
}
.about_img{
  border-radius: 10px;
  margin-top: 70px;
  // height: 70%;
}
.dot-svg {
  left: -80px;
  top: -230px;
  position: relative;
  display: inline-block;
}
.circle-svg{
  left: 120px;
  top: -30px;
  position: relative;
  display: inline-block;
  width: 60px;
}
}

.section-headding .section-topik {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  padding-left: 80px;
  position: relative;
  font-size: 18px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.section-headding .section-topik:before{
  content: "";
  position: absolute;
  width: 80px;
  height: 2px;
  background: #FFFFFF;
  top: 14px;
  left: 0;
}


.service-part {
  background: rgba(211, 213, 214, 0.4);
  // padding-bottom: 30px;
  padding-top: 90px;
  margin-top: 30px;
 border-radius: 5px;

}
.service-left {
  width: 100%;
  padding-left: 20px;
}
.animated {
-webkit-animation-duration: 1s;
animation-duration: 1s;
-webkit-animation-fill-mode: both;
animation-fill-mode: both;
}
.fadeInLeft {
-webkit-animation-name: fadeInLeft;
animation-name: fadeInLeft;
}
.headding-button .section-headding {
float: left;
}
.section-headding {
position: relative;
z-index: 3;
}
.section-headding.color-headding .section-topik {
color: #595959;
}
.section-headding .section-topik {
color: #FFFFFF;
font-family: "Montserrat", sans-serif;
padding-left: 100px;
position: relative;
margin-bottom: 45px;
text-transform: uppercase;
}
label {
display: inline-block;
margin-bottom: 0.5rem;
}
.section-headding.color-headding .section-topik:before {
background: #EF7B00;
}
.section-headding .section-topik:before {
content: "";
position: absolute;
width: 80px;
height: 2px;
background: #FFFFFF;
top: 14px;
left: 0;
}
.section-headding.color-headding .section-title {
color: #EA3516;
}
.service-tab {
margin-top: 115px;
// text-align: center;
}
.service-tab img{
  width: 35px;
}
.service-tab .mt0{
  margin-top: -10px;
  width: 30px !important;
}
.service-tab .nav-tabs {
border-bottom: 0;
// margin-left: -10px;
// margin-right: -10px;
}
.nav-tabs {
border-bottom: 1px solid #dee2e6;
}
.nav {
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
padding-left: 0;
margin-bottom: 0;
list-style: none;
}
.service-tab .nav-tabs .nav-item {
padding: 0px 9px;
width: 33.33%;

}
.nav-tabs .nav-item {
margin-bottom: -1px;
}
a {
outline: none !important;
text-decoration: none;
-webkit-box-shadow: unset !important;
box-shadow: unset !important;
cursor: pointer;
text-decoration: none;
color: #595959;
-webkit-transition: all 400ms ease;
transition: all 400ms ease;
}
.nav-link {
display: block;
padding: 0.5rem 1rem;
}
.nav-tabs .nav-link {
border: 1px solid transparent;
border-top-left-radius: 0.25rem;
border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link.active {
color: #495057;
background-color: #fff;
border-color: #dee2e6 #dee2e6 #fff;
}
.service-tab .nav-tabs .nav-item .nav-link {
border: 0 !important;
background: #FFA800;
color: #FFFFFF !important;
padding: 30px;
font-weight: 700;
border-radius: 10px !important;
height: 90%;
position: relative;

}
.service-tab .nav-tabs .nav-item .nav-link:hover {
  background: #cc8600;
}

.service-tab .nav-tabs .nav-item:nth-child(2) .nav-link {
  background: #A7A7A7;
}

.service-tab .nav-tabs .nav-item:nth-child(2) .nav-link:hover {
  background: #8e8e8e;
}

.service-tab .nav-tabs .nav-item:nth-child(3) .nav-link {
  background: #EF7B00;
}

.styles_topmenu__oGT_u .styles_icon__1uWQb:nth-child(1){
  display: none !important;
}

.service-tab .nav-tabs .nav-item:nth-child(3) .nav-link:hover {
  background: #bc6100;
}
.service-right {
  // margin-left: 80px;
  padding-right: 10px;
  padding-top: 90px;
}
.fadeInRight {
-webkit-animation-name: fadeInRight;
animation-name: fadeInRight;
}
.animated {
-webkit-animation-duration: 1s;
animation-duration: 1s;
-webkit-animation-fill-mode: both;
animation-fill-mode: both;
}
.service-right {
position: relative;
}
.image-redius {
border-radius: 50px;
max-width: 600px;
width: 100%;
}

.image-overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: #E7A583;
border-radius: 50px;
opacity: 0.1;
}
.service-right .dot-svg {
  left: -70px;
  top: -300px;
  position: relative;
  display: inline-block;
}
@media (max-width: 1800px){
.service-tab .nav-link {
    padding: 20px !important;
}
}

@media (max-width: 1400px){
.service-tab .nav-tabs .nav-item .nav-link {
    font-size: 14px;
    padding: 23px !important;
}
}
@media (max-width: 1400px){
.image-redius {
    max-width: 450px;
}
}
@media (max-width: 1600px){
.image-redius {
    max-width: 500px;
}}

.service-img-responsive {
  display: none;
}

.usage_card .card-body{
  // position: relative !important;
  // background-color: white !important;
  // box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
//  margin-right: 5px;
//  border-radius: 10px;
//  min-width: 60%;
//  height: 60% !important;
//  overflow: auto;

}
.usage_card{
 
  // position: relative;
  
  
}
.usages{
  overflow-y: scroll !important;
  height: 60vh;
  background-color: #f7f2ee;
  padding-top: 5px;
  border-radius: 10px;

}
.usages_filter .form-select{
  width: 120px !important;
}
.usages_filter{
  // display: flex;
  gap: 8px;
  justify-content: end;
  margin-bottom: 15px;
  
}
.filter{
  position: relative;
  // top: 280px;
  // margin-top: 30px;
 
  // right: 10px;
  
}
.mt-46{
  // margin-top: 46px !important;
}

.footer_usages{
  position: fixed;
  left: 30%;
}

.profile-dropdown.show .dropdown-menu[x-placement^=bottom] {
  left: -22px !important;
}
// banner

.banner{
  
 
  border-radius: 8px;
  
  
}
.gif_comingsoon{
  text-align: center;
  justify-content: center;
  align-items: center;
  img{
    width: 200px;
    border-radius: 10px;

  }
}


.side-menu__item:hover .side-menu__icon {transform: rotate(360deg) ;
  transition: transform .7s ; 
}


.SearchResults-module_list_2AV2c {
  // width: 100%;
  // display: flex;
  // flex-wrap: wrap;
  // margin-top: 10px;
  
}

.segment_sec{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.SearchResults-module_result_3-uXL {
  // width: 25%;
}
.SearchResults-module_result_3-uXL {
  display: flex;
 
  position: relative;
  // text-align: center;
  // width: 20%;
  // box-sizing: border-box;
}
a:not(img) {
  text-decoration: none;
  color: #5672c4;
  vertical-align: baseline;
  cursor: pointer;
}
.ContentCard-module_card_1xFCo {
  position: relative;
  background-color: #faf5ed;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 1px 2px 0 rgb(0 0 0 / 24%);
  display: inline-block;
  // margin: 7px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-width: 240px;
  max-width: 240px;
  max-height: 295px;
  transition: box-shadow .2s;
  // margin-bottom: 20px;
}
.ContentCard-module_link_bi8gq {
  display: block;
  /* width: 230px; */
  height: 295px;
}
.ContentCard-module_front__1Cvl {
  border-radius: 4px;
  box-sizing: border-box;
  width: 240px;
  height: 295px;
  left: 0;
  overflow: hidden;
  white-space: normal;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.ContentCard-module_image_1D6-7.ContentCard-module_narrow_1Ktvs {
  background-color: #faf5ed;
  // height: 172px;
  margin: 0px auto 0;
  width: 100%;
}
.ContentCard-module_image_1D6-7 {
  position: relative;
  background-color: #fff;
  border: 0;
  box-sizing: border-box;
  // height: 192px;
  overflow: hidden;
}
.ContentCard-module_titleArea_sGOV3 {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 30%);
  box-sizing: border-box;
  height: 68px;
  padding: 3px 20px 0;
  position: relative;
  text-align: left;
}
.font-primary-bold {
  font-family: "Montserrat";
  font-weight: 500;
}
.Title-module_title_21JAg {
  color: #464646;
  font-size: 16px;
  height: 40px;
  line-height: 18px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.ContentType-module_contentTypeHolder_7Ku6w {
  bottom: 16px;
  color: #878787;
  font-size: 14px;
  line-height: 1.375;
  left: 20px;
  position: absolute;
  text-transform: capitalize;
}
.SearchResults-module_result_3-uXL{
  transition:none;
  opacity: 1;
}
.SearchResults-module_result_3-uXL:hover .ContentCard-module_hoverSection_2GeOZ {
  position: absolute;
  top: 0;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 30%);
  box-sizing: border-box;
  color: #464646;
  display: flex;
  flex-direction: column;
  white-space: normal;
  width: 240px;
  height: 290px;
  margin-top: 50px;
  opacity: 1;
  padding: 10px 20px 0;
  text-align: left;
  transform: translateY(-20px);
  transition: translateY(-20px);
  transition: transform .3s,opacity .15s;
  z-index: 2;
  pointer-events: none;
}
.font-primary-bold {
  font-family: "Montserrat";
  font-weight:bold !important;
  font-size: 14px;
  color: #031b4e;
}
.ContentCard-module_description_1ejK0 {
  font-size: 14px;
  line-height: 1.375;
  height: 100%;
  margin-bottom: 4px;
  overflow: hidden;
}
.ContentCard-module_categories_343y- {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ContentType-module_contentTypeHolder_7Ku6w {
  bottom: 16px;
  color: #878787;
  font-size: 14px;
  line-height: 1.375;
  left: 20px;
  position: absolute;
  text-transform: capitalize;
}
.ul_style{
  list-style-type:none ;
  padding-left: 5px !important;
  
}
.ul_style > li::before{
  content: "-";
  text-indent: -5px;
  margin-right: 3px;
}

.togglebtn{
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
.btn-toggle {
  font-size: 10px;
  min-width: 80px;
  padding-left: 4px;
  padding-right: 4px;
 
  color: #222020 !important;
  background-color: #f5debf;
  border-color: #f5debf;
  border-radius: 35px;
  box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
}

.btn-toggle-selected{
  background-color: #f5debf;
  border-color: #f5debf;
}

.btn-toggle-deselected{
  background-color: #fff;
  border-color: #fff;
}

.toggle{
  .card-body{
    padding: 8px;
  }
}

.tog .row {
  display: flex;
  flex-wrap: wrap;
 
}
.tog .row > [class*='col-'] {
  display: grid;
  flex-direction: column;
  position: relative;
  

}


// data T

.styles_lightroom__1X2qE {
  height: 100vh !important;
  width: 100vw !important;
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: all 0.15s;
  visibility: hidden;
  z-index: 99999 !important;
}

.Tabulation_sec{
  // background-color: #d6d0d070;
}
.styles_column__2r45X {
  margin-bottom: 20px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.styles_row__2gmnz {
  column-count: 4;
  transition: all .5s ease-in-out;
  column-gap: 14px;
  column-fill: initial;
}
.styles_column__2r45X img {
  vertical-align: middle;
  width: 100%;
  border-radius: 5px;
  // height: 150px;
}

.lightbox-gallery{background-image: linear-gradient(#4A148C, #E53935);background-repeat: no-repeat;color: #000;overflow-x: hidden}
.lightbox-gallery p{color:#fff}
.lightbox-gallery h2{font-weight:bold;margin-bottom:40px;padding-top:40px;color:#fff}

// /--------------------
//     Cases Page Style
// ---------------------
.cases-listing {
  padding-top: 30px;
}
.work-grid-box {
  margin-bottom: 30px;
}

.grid-box-in{
  overflow: hidden;
  position: relative;
  .cases-img {
      border-radius: 10px;
      overflow: hidden;
      a {
          display: block;
      }
      img {
          width: 100%;
          transition: all 400ms ease;
      }
  }
  .cases-overly {
      
      display: block;
      margin-top: 15px;
      .cases-text {
          position: relative;
          color: #555;
          font-weight: 700;
          font-family: inherit;
      }
      &:hover{
        text-decoration: none;
          .cases-text{
              color: #094990;
              
          }
      }
  }
  &:hover{
      .cases-overly{
          top: 0%;
      }
      .cases-img img{
          transform: scale(1.1);
      }
  }

}

/*--------------------
    Cases Detail Page Style
---------------------*/
.col-lg-8.work-grid-box{
  position: relative;
}
.col-lg-8.work-grid-box::after{
  content: "";
  position: absolute;
  width: 140px;
  height: 50px;
  background: url(../public/img/dot.svg) no-repeat;
  background-size: contain;
  z-index: 1;
  right: -5px;
  top: 60px;
}
.business-goals {
  position: relative;
}
.cases-detail-main{
  padding-top: 30px;
}
.business-goals-img {
  position: relative;
  .image-redius{
      max-width: unset;
  }
  .dot-svg {
      top: 50px;
  }
}
.business-goals-content {
  ul {
      padding-left: 0px;
      li {
          padding-bottom: 10px;
          a {
              background: rgba(rgb(208, 208, 208), 0.4);
              font-weight: 700;
              padding: 15px 25px;
              display: block;
              color: #555;
              font-size: 16px;
              position: relative;
              &:before{
                  content: "";
                  background: #E63E11;
                  width: 6px;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;
                  opacity: 0;
                  transition: all 400ms ease;
              }
              &:hover{
                  color: #E63E11;
                  text-decoration: none;
                  &:before{
                      opacity: 1;
                  }
              }
          }
          &:first-child{
              a{
                  &:before{
                      content: "";
                      background: #E63E11;
                      width: 6px;
                      height: 100%;
                      position: absolute;
                      left: 0;
                      top: 0;
                      opacity: 1;
                  }
              }
          }
      }
  }
}
.cases-round {
  background: rgba(#333, 0.09);
  width: 700px;
  height: 700px;
  position: absolute;
  bottom: 0;
  right: -24%;
  border-radius: 100%;
  &:before{
      content: "";
      background: url(../public/img/dubbolround-border.svg);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-repeat: no-repeat;
      background-size: 170px;
      background-position: 1% 1%;
      opacity: 0;
      visibility: hidden;
  }
  &.animated{
      &:before{
          opacity: 1;
          visibility: visible;
          transition-delay: 1s;
          transition-duration: 2s;
      }
  }
}
.cases-detail{
  border-top: 1px solid #D3D5D6;
  margin-top: 50px;
  .cases-content{
    padding-top: 30px;
  }
}
.cases-box {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0px;
  border-bottom: 1px solid #D3D5D6;
  &:first-child {
      padding-top: 0;
  }
  &:last-child{
      border-bottom: 0;
      padding-bottom: 0;
  }
}
.cases-left {
  width: 210px;
  padding: 0px 15px;
  padding-left: 0;
}
.cases-title {
  font-size: 20px;
  font-weight: 700;
  color: inherit;
  position: relative;
  line-height: 30px;
  &:before {
      content: "";
      background: orange;
      width: 50px;
      height: 2px;
      position: absolute;
      bottom: -10px;
      left: 0;
  }
}
.cases-right {
  width: calc(100% - 210px);
  padding: 0px 15px;
  padding-right: 0;
  p {
      margin-bottom: 0;
  }
  ul {
      li {
          position: relative;
          padding-left: 20px;
          &:before {
              content: "";
              position: absolute;
              top: 12px;
              left: 0;
              width: 8px;
              height: 8px;
              background: #333;
              border-radius: 100%;
          }
      }
  }
}
.lightbox-gallery .intro{font-size:16px;max-width:500px;margin:0 auto 40px}.lightbox-gallery .intro p{margin-bottom:0}.lightbox-gallery .photos{padding-bottom:20px}.lightbox-gallery .item{padding-bottom:30px}

@media (max-width:767px){
.lightbox-gallery h2{margin-bottom:25px;padding-top:25px;font-size:24px}}.lightbox-gallery .intro{font-size:16px;max-width:500px;margin:0 auto 40px}.lightbox-gallery .intro p{margin-bottom:0}.lightbox-gallery .photos{padding-bottom:20px}
.lightbox-gallery .item{padding-bottom:30px}

.light_room{
  cursor: pointer;
}

.lb-container {
   z-index: 50000; 
   position: absolute !important; 
  
  left: 0px;
  top: 0px;
   width: 100%;
  height: 100%; 
   background-color: rgb(255, 255, 255) !important; 
  background-color: rgb(255, 255, 255) !important; 
  display: flex; 
  flex-direction: column; 
  border-radius: 4px;
}
.cases-box:first-child {
  padding-top: 0;
}
@media (max-width:767px){
  .lightbox-gallery h2{margin-bottom:25px;padding-top:25px;font-size:24px}
  .cases-detail {
    margin-top: 15px;
    padding-top: 15px;
    .cases-content {
      width: 100%;
    }
  }
  
  .cases-box {
    padding: 20px 0px;
    
}
  .cases-left {
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
  }
  .cases-right {
    width: 100%;
    padding: 0;
}

}



