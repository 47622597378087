.card {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}
.errorMessage {
    color: red;
    position: absolute;
  margin-left: 5px;
}
.errorMessageform {
    color: red;
    position: absolute; 
  /* margin-left: 5px;
}
.scrool{
    overflow-y: scroll !important;
    height: 100vh;
}
.scrool {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.footer{
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}
.btn-primary{
    width: 100% !important;
    height: 40px !important;
    font-size: 15px !important;
}
.table_scripting .h1{
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    color: rgb(48, 48, 228);
}
.title_step{
    /* color: rgb(48, 48, 228); */
    /* font-size: 17px; */
    
}
.table_scripting .hr{
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    color: rgb(5, 5, 255);
}

.table_scripting .bg-primary{
    color: white !important;
}

/* awselect.min.css */

.immersive_awselect {
    overflow: hidden !important
}

.awselect_bg {
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(0, 0, 0, .8);
    -webkit-transition: .2s ease-in;
    -moz-transition: .2s ease-in;
    -o-transition: .2s ease-in;
    transition: .2s ease-in
}

.awselect,
.awselect>.bg {
    -webkit-transition: .4s cubic-bezier(.19, 1, .22, 1);
    -moz-transition: .4s cubic-bezier(.19, 1, .22, 1);
    -o-transition: .4s cubic-bezier(.19, 1, .22, 1);
    float: left;
    width: 100%
}

.awselect_bg.animate {
    opacity: 1
}

.awselect {
    position: relative;
    cursor: pointer;
    transition: .4s cubic-bezier(.19, 1, .22, 1)
}

.awselect>.bg {
    background: #fff;
    height: 0%;
    position: absolute;
    z-index: 1;
    transition: .4s cubic-bezier(.19, 1, .22, 1)
}

.awselect .front_face {
    position: relative;
    padding: 20px 40px;
    color: #000;
    float: left;
    width: 100%
}

.awselect .front_face>.bg {
    position: absolute;
    z-index: 0;
    float: left;
    width: 100%;
    height: 100%;
    left: 0;
    background: #e5e5e5;
    top: 0
}

.awselect .front_face .content {
    float: left;
    width: 100%;
    position: relative;
    z-index: 2
}

.awselect .front_face span {
    -webkit-transition: .4s cubic-bezier(.19, 1, .22, 1);
    -moz-transition: .4s cubic-bezier(.19, 1, .22, 1);
    -o-transition: .4s cubic-bezier(.19, 1, .22, 1);
    transition: .4s cubic-bezier(.19, 1, .22, 1);
    float: left;
    width: 100%;
    color: inherit
}

.awselect .front_face .current_value {
    position: absolute;
    left: 0
}

.awselect .front_face .placeholder {
    position: relative
}

.awselect .back_face,
.awselect .front_face .icon {
    position: absolute;
    -webkit-transition: .4s cubic-bezier(.19, 1, .22, 1);
    -moz-transition: .4s cubic-bezier(.19, 1, .22, 1);
    -o-transition: .4s cubic-bezier(.19, 1, .22, 1)
}

.awselect .front_face .icon {
    float: right;
    right: 0;
    top: 50%;
    transform: rotate(180deg) translateY(-50%);
    transform-origin: center 0;
    transition: .4s cubic-bezier(.19, 1, .22, 1)
}

.awselect .front_face .icon svg {
    width: 10px;
    height: 10px
}

.awselect .back_face {
    z-index: 2;
    padding: 0;
    transition: .4s cubic-bezier(.19, 1, .22, 1);
    transform: translateY(20px);
    opacity: 0;
    float: left;
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    display: none
}

.aw_cursor_pointer,
.awselect .back_face ul li a {
    -webkit-transition: .4s cubic-bezier(.19, 1, .22, 1);
    -moz-transition: .4s cubic-bezier(.19, 1, .22, 1);
    -o-transition: .4s cubic-bezier(.19, 1, .22, 1);
    float: left
}

.awselect .back_face.overflow {
    overflow-y: scroll
}

.awselect .back_face ul {
    margin: 0;
    float: left;
    padding: 0;
    width: 100%
}

.awselect .back_face ul li {
    float: left;
    width: 100%;
    display: block
}

.awselect .back_face ul li a {
    color: inherit;
    opacity: .8;
    width: 100%;
    transition: .4s cubic-bezier(.19, 1, .22, 1)
}

.awselect .back_face ul li a:hover {
    background: rgba(0, 0, 0, .07);
    opacity: 1
}

.awselect.animate {
    z-index: 990
}

.awselect.animate .front_face .icon {
    transform: rotate(0) translateY(-50%)
}

.awselect.animate>.bg {
    height: 100%
}

.awselect.animate2>.bg {
    /* box-shadow: 0 11px 43px 11px rgba(0, 0, 0, .11) */
}

.awselect.animate2 .back_face {
    transform: translateY(0);
    opacity: 1
}

.awselect.hasValue .front_face .placeholder {
    transform: translateY(20px);
    opacity: 0
}

.awselect.placeholder_animate .front_face .current_value {
    opacity: 0
}

.awselect.placeholder_animate2 .front_face .placeholder {
    transform: translateY(0);
    opacity: 1
}

.awselect.transition_paused {
    transition: none !important
}

.aw_cursor_pointer {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #ddd;
    overflow: hidden;
    padding: 5px;
    transition: .4s cubic-bezier(.19, 1, .22, 1);
    transform: scale(.5)
}

.aw_cursor_pointer .inner {
    float: left;
    width: 100%;
    height: 0%;
    background: #fff;
    border-radius: 100%;
    -webkit-transition: .4s cubic-bezier(.19, 1, .22, 1);
    -moz-transition: .4s cubic-bezier(.19, 1, .22, 1);
    -o-transition: .4s cubic-bezier(.19, 1, .22, 1);
    transition: .4s cubic-bezier(.19, 1, .22, 1)
}

.aw_cursor_pointer.animate {
    transform: scale(1)
}

.aw_cursor_pointer.animate .inner {
    height: 100%
}

.policy{
    font-size: 10px !important;
}
.checkbox_survey .checkbox{
    /* font-size: 30px; */
    align-items: center !important;
    text-align: center !important;

}
.checkbox_survey{
    align-items: center !important;
    /* text-align: center !important; */
    padding-top: 5px;
   
}
.checkbox_survey input[type=checkbox] {
    position: relative;
      cursor: pointer;
      width: 19px; /*Desired width*/
  height: 19px; /*Desired height*/
  cursor: pointer;
 
}

.toggle_lan{
    display: flex;
    gap: 40px;
    padding-top: 3px;
}
.delete_lan{
    border: white;
    background-color: rgba(247, 50, 50, 0.788);
    color: #eeeef1;
    box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
    /* text-align: left; */
    margin-bottom: 6px;
    font-size: 13px !important;
    font-weight: 700;
    position: relative;
    cursor: pointer;
    border: white;
    border-radius: 5px;
    width: 100%;
    height: 40px !important;
}

.add_lan{
    
    /* background: rgb(22, 22, 71) !important; */
    color: #1a1a1b !important;
    display: inline-block !important;
    top: -33px;
    box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
    text-align: center;
   
    font-size: 11px !important;
    font-weight: 900 !important;
    position: relative;
    cursor: pointer;
    border: white;
    border-radius: 20px;
    width: 20% !important;
    background-color: #f5f1f1 !important;
    height: 25px !important;
   
    /* font-weight: 400; */
   
    
}
.add_lan:hover{
    color: #000 !important;
}
.add_Language{
    /* text-align: center; */
}
.lan_count{
    display: inline-block !important;
    position: relative;
    top: -32px;
    font-size: 11px;
}
.steps_survey{
    background-color: #007bff;
    color: white;
    width: 35%;
    padding: 8px;
    border-radius: 30px;
}
.ptsurvey{
    padding-top: 0px !important;
}
.step_surveyscript{
    display: inline-block !important;
    position: relative;
    top: -17px;
    font-size: 16px;
    font-weight: 700;
}
.ques_count{
    display: inline-block !important;
    position: relative;
    top: -13px;
    font-size: 11px;
}
.add_qustions{
    text-align: right;
    align-items: center;
    padding-top: 12px;
}

.add_qus{
    
    color: #eeeef1;
    box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
    /* text-align: left; */
    margin-bottom: 5px;
    font-size: 12px !important;
    font-weight: 700;
    position: relative;
    cursor: pointer;
    border: white;
    border-radius: 5px;
    /* width: 116px !important; */
    height: 35px !important;
    margin-bottom: 10px;
    background-color: #999494 !important;
    
}

/* forgot password */

.forgotpass{
    text-align: right !important;
}


/* sign css */

.sign .input-box .form-control{
    padding: 10px !important;
    height: 32px !important;
    border-radius: 3px;
}

.checkbox_survey_flex{
    display: flex;
    /* justify-content: center; */
    text-align: center;
    height: 50px;
    gap: 6px;
}
.main_qus_s{
    width: 116px !important;
}
.float_s{
    text-align: right !important;
}
.Upload_qus{
    /* width: 80% !important; */
    /* background: rgb(22, 22, 71) !important; */
    color: #eeeef1;
    box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
    /* text-align: left; */
    
    font-size: 13px !important;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    border: white;
    border-radius: 5px;
    width: 100%;
  
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    
}

.required_f{
    font-size: 12px;
    color: red;
}
.required_fop{
    font-size: 12px;
    color: #727274dc;
}

.main_qus_s{
    /* background: rgb(22, 22, 71) !important; */
    color: #eeeef1;
    box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
    /* text-align: left; */
    
    font-size: 14px !important;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    border: white;
    border-radius: 5px;
    /* width: 100%; */
  
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}
.main_qus_sample{
    color: #eeeef1;
    box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
    /* text-align: left; */
    
    font-size: 14px !important;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    border: white;
    border-radius: 5px;
    width: 30% !important ;
  
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}
.main_qus_sample_usd{
    color: #eeeef1;
    box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
    /* text-align: left; */
    
    font-size: 14px !important;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    border: white;
    border-radius: 5px;
    width: 50% !important ;
  
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}
.main_qus_sample_upload{
    color: #eeeef1;
    box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
    /* text-align: left; */
    
    font-size: 14px !important;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    border: white;
    border-radius: 5px;
    width: 50% !important ;
  
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}
.side-menu .side-menu__icon {
    font-size: 17px;
    text-align: center;
    vertical-align: middle;
    margin-right: 10px;
    width: 40px;
    padding: 8px;
}
.side-menu__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 7px 22px 3px 13px;
    font-size: 14px;
    font-weight: 400;
    transition: border-left-color 0.2s ease, background-color 0.2s ease;
    color: #1e1e2d;
    margin: 0px 5px 0px 0px;
    border-radius: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.breadcrumb_img{
    width: 30px !important;
}
.table_scripting .thh{
font-size: 12px !important;
text-align: center;
/* vertical-align: middle !important; */
}

    
.table_scripting table{

}

.form_fill_formcontrol .form-control{
    height: 32px !important;
    font-size: 12px;

}
.form_fill_formcontrol .form-control:focus{
    box-shadow: none !important;
    
}
.h-7{
    height: 40px !important;
}
.survey_sampling_checkbox{
justify-content: start;
/* margin-left: 100px; */
text-align: center;
}
.survey_sampling_checkbox input[type=checkbox] {
    position: relative;
      cursor: pointer;
      width: 17px; /*Desired width*/
  height: 17px; /*Desired height*/
  cursor: pointer;
  margin-top: 2px;
 
}

/* usage habits */
.side-menu__item_usage {
    position: relative;
    display: flex;
    align-items: center;
    padding: 2.5px 22px 3px 13px;
    font-size: 14px;
    font-weight: 400;
    transition: border-left-color 0.2s ease, background-color 0.2s ease;
    color: #1e1e2d;
    margin: 0px 5px 0px 0px;
    border-radius: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .side-menu__item_usage:hover, .side-menu__item_usage:focus {
    text-decoration: none;
    background: #eef0f1;
    color: #1e1e2d;
    border-left: 3px solid #f3a033;
    transition: border-left 0.2s ease-in-out, color 0.2s ease;
  }
  .side-menu__item_usage:hover .side-menu__icon, .side-menu__item_usage:focus .side-menu__icon {
    color: #f3a033;
  }
  .side-menu__item_usage:hover .angle, .side-menu__item_usage:focus .angle {
    color: #f3a033;
  }
  
  .side-menu__item_usage.active .side-menu__icon {
    color: #fff;
  }
  .side-menu__item_usage.active:hover .angle, .side-menu__item_usage.active:focus .angle {
    color: #fff;
  }
  .side-menu__item_usage.active:hover, .side-menu__item_usage.active:focus {
    transition: none;
  }
  
.counter {
    min-height: 120px;
border: 0.5px solid rgb(238, 231, 231);
cursor: pointer;
/* background-color:rgb(250, 245, 245) !important; */

}
.mt-1c{
    margin-top: 0.75rem !important;
}

.counter-inner {position: relative;z-index: 2;}
.counter .counter-inner h6{
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #031b4e ;
}
.counter .counter-inner h6:hover{
    color: #f3a033 ;
}

.counter img{
    width: 60px;
    padding-top: 7px;
    transition: all .3s ease;
}

.usages .side-menu{
    margin-top: 0px !important;
    border-top: white !important;
                
}
.usages .side-menu .side-menu__item_usage{


    background-color: rgb(255, 255, 255) ;
    padding-left: 14px !important;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 18px !important;
    border-radius: 10px !important;
    cursor: pointer;
  
}
.usages .side-menu  .Tour_and_Travels{
    background-color: #f3a033 ;
    color: #ffffff;
}
.usages .side-menu  .Personal_Care{
    background-color: #f3a033 ;
    color: #ffffff;
}
.usages .side-menu  .Media_Users{
    background-color: #f3a033 ;
    color: #ffffff;
}
.usages .side-menu  .Beverages{
    background-color: #f3a033 ;
    color: #ffffff;
}
.usages .side-menu  .Financial_Investments{
    background-color: #f3a033 ;
    color: #ffffff;
}
.usages .side-menu  .Chocolates_Confectionaries{
    background-color: #f3a033 ;
    color: #ffffff;
}
.usages .side-menu  .Toiletries{
    background-color: #f3a033 ;
    color: #ffffff;
}

.usages .side-menu .side-menu__label_usage{

    font-size: 13px !important;
    font-weight:500;
}
.side-menu__label_usage {
    white-space: nowrap;
    flex: 1 1 auto;
    font-size: 12px;
    font-weight: 400;
    opacity: 1;
    height: auto;
    transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  
.usages .slide{
    margin-bottom: 6px;
}

.usages .side-menu .active{
    background-color: #f5debf;
    color: #031b4e;
    border-left: 3px solid #f3a033;
}

.avatar .userLetter{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #3498db;
    color: #fff;
    text-align: center;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}


.service-part1{
    background: rgba(145, 178, 196, 0.4);
    padding-top: 65px;
    margin-top: 30px;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
}
.service-part1 img{
    border-radius: 10px;
}

 .service-part1_mar {
    
    margin-bottom: 30px !important;
    /* text-transform: uppercase; */
}

.trash_ques {
    font-size: 16px;
    position: relative;
    /* display: inline-block; */
    top: -42px;
    right: -300px;
    float: right;
    
}